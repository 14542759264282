import React from "react";
import { BiMessage } from "react-icons/bi";

import { LikeButton } from "@/components/buttons/LikeButton/LikeButton";
import Tag from "@/components/buttons/Tag/Tag";
/* eslint-disable @next/next/no-img-element */
import Card from "@/components/card/Card";
import CardFooter from "@/components/card/CardFooter/CardFooter";
import { P } from "@/components/typography";
import { parseDate } from "@/utils/parseDate";
import { truncate } from "@/utils/truncate";

type Props = {
  btnHidden?: boolean;
  title: string;
  titleHighlighted?: string;
  imgSrc: string;
  categories?: string[];
  content: string;
  bg?: string;
  imgHeight?: number;
  className?: string;
  buttonColor?: string;
  tags?: string[];
  author?: string;
  postDate: Date;
  likesCount?: number;
  commentsCount?: number;
  id: string;
  type?: "blog" | "vlog";
};

export default function HomeBlog({
  imgSrc,
  title,
  titleHighlighted,
  className,
  btnHidden,
  categories,
  content,
  imgHeight = 180,
  bg = `#3FC7B4`,
  buttonColor = "#3FC7B4",
  tags = [],
  author,
  likesCount = 0,
  commentsCount = 0,
  postDate,
  id,
  type,
}: Props) {
  return (
    <Card variant="brief" className={className}>
      {imgSrc && (
        <img
          src={imgSrc}
          className="h-[400px] w-full object-cover"
          alt={title}
        />
      )}
      <CardFooter
        className={`bg-[${bg}] p-[24px] md:py-[32px] md:pb-[24px] md:pt-[20px]`}
      >
        <div className={`flex h-full flex-col gap-[24px]`}>
          <div className="flex flex-col gap-2">
            <h4 className="text-new" style={{ color: "#fff" }}>
              {type == "vlog" ? "👀" : "📖"} {title}
            </h4>
            <P style={{ color: "#fff" }} className={`m-0`}>
              {author}
            </P>
          </div>
          <div className={"flex flex-wrap gap-2"}>
            {categories &&
              categories.slice(0, 3).map((t) => {
                return (
                  <Tag
                    key={t}
                    variant="dark"
                    size="m"
                    style={{
                      backgroundColor: "white",
                      color: `#3FC7B4`,
                      fontFamily: "Fjalla One",
                    }}
                  >
                    {t}
                  </Tag>
                );
              })}
          </div>
          <P
            className="transition"
            style={{
              maxHeight: 200,
              marginTop: 12,
              fontWeight: "300",
              overflow: "hidden",
              color: "#fff",
            }}
          >
            {truncate(content, 200)}
          </P>
          <div className="author-date mt-auto flex items-center justify-between">
            <div className="icon-wrapper mr-4">
              <div className="likes flex gap-1">
                <LikeButton
                  likeCount={likesCount}
                  entityId={id}
                  entityType={"blog"}
                  bgColor={"#3FC7B4"}
                  strokeColor={"#fff"}
                />
              </div>
              <div className="comments flex gap-1">
                <BiMessage size={24} stroke={"#fff"} fill={"#fff"} />
                <p className="font-avenir text-[16px] font-light text-white md:text-[18px]">
                  {commentsCount}
                </p>
              </div>
            </div>
            <p
              className="font-avenir font-light italic text-white "
              style={{ fontSize: "18px" }}
            >
              Geplaatst op {parseDate(postDate)}
            </p>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}
