import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { getMenuItems } from "@/api/api";
import CloseIcon from "@/components/icons/CloseIcon/CloseIcon";
import Logo from "@/components/icons/Logo/Logo";
import MenuIcon from "@/components/icons/MenuIcon/MenuIcon";
import NavbarSearchIcon from "@/components/icons/NavbarSearchIcon/NavbarSearchIcon";
import SearchIcon from "@/components/icons/SearchIcon/SearchIcon";
import { P } from "@/components/typography";
import { COLORS } from "@/styles/theme";
import { ContentStatus } from "@/types/content-types/Status.type";
import { useOnClickOutsideEl } from "@/utils/eventHandlers";

import HeaderNav from "./HeaderNav/HeaderNav";
import HeaderSubmenuMobile from "./HeaderSubmenuMobile/HeaderSubmenuMobile";

export type MenuItem = {
  id: string;
  name: string;
  status?: ContentStatus;
  isForAdults: boolean;
  children: ChildMenuItem[];
  url: string;
};

export type ChildMenuItem = {
  id: string;
  name: string;
  status?: ContentStatus;
  children: GrandChildMenuItem[];
};

export type GrandChildMenuItem = {
  id: string;
  name: string;
  status?: ContentStatus;
  link: string;
};

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white.normal};
  z-index: 9999;

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 41px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    height: 110px;

    > div:first-child {
      display: flex;
      align-items: center;
      background-color: white;
      margin-right: 8px;
    }

    > div:not(:first-child) {
      display: flex;
      align-items: center;
      background-color: white;
      gap: 36px;
    }
  }

  .mobile-menu {
    display: block;
    width: 100%;

    .inner {
      height: 60px;
    }
  }

  .desktop-menu {
    display: none;

    .inner + div {
      width: 100%;
    }
  }

  @media ${({ theme }) => theme.devices.laptop} {
    .mobile-menu {
      display: none;
    }

    .desktop-menu {
      display: block;
    }
  }
`;

export default function Header() {
  const [isLoading, setIsLoading] = useState(true);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [selected, setSelected] = useState<MenuItem | undefined>(undefined);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const router = useRouter();

  const elRef = useRef(null);
  const mobileMenuRef = useRef(null);

  const sortedMenuItems = menuItems.sort(
    (a, b) => Number(a.isForAdults) - Number(b.isForAdults),
  );

  const closeMenu = () => {
    setSelected(undefined);
    setMobileMenuOpen(false);
  };

  useOnClickOutsideEl(elRef, () => closeMenu());
  useOnClickOutsideEl(mobileMenuRef, () => closeMenu());

  useEffect(() => {
    router.events.on("routeChangeStart", () => closeMenu());

    return () => {
      router.events.off("routeChangeStart", () => closeMenu());
    };
  }, [router]);

  const toggleMenu = (menu: MenuItem) => {
    if (menu.url === "#" || menu.url === "") {
      if (!mobileMenuOpen) return;
    }

    if (selected?.id === menu.id) {
      return setSelected(() => undefined);
    }

    return setSelected(() => menu);
  };

  useEffect(() => {
    const getData = async () => {
      const data = await getMenuItems();

      if (data) {
        const sortedArray = [...data].sort(
          (a, b) => b.children.length - a.children.length,
        );

        setMenuItems(sortedArray);
        setSelected(sortedArray[0]);
      }
      setIsLoading(false);
    };

    if (menuItems.length === 0 && isLoading) {
      getData();
    }
  }, [isLoading, menuItems]);

  const donate: MenuItem | undefined = menuItems.find(
    (item) => item.name === "Doneer nu",
  );

  const menuItemsForAdults = menuItems.filter(
    (item) => item.isForAdults && item.status === "published",
  );
  const menuItemsForChildren = menuItems.filter(
    (item) => !item.isForAdults && item.status === "published",
  );

  return (
    <StyledHeader ref={elRef}>
      <div
        style={{ backgroundColor: COLORS.primary.normal }}
        className="hidden w-full items-center justify-end lg:flex"
      >
        <HeaderNav
          isForAdults
          menuCols={menuItemsForAdults}
          onChange={(item) => toggleMenu(item)}
          selected={selected}
        />
      </div>

      <div className="desktop-menu new-menu">
        <div className="inner">
          <div>
            <Link href="/">
              <Logo />
            </Link>
            {!isLoading && (
              <HeaderNav
                isForAdults={false}
                menuCols={menuItemsForChildren}
                selected={selected}
                onChange={(x) => toggleMenu(x)}
              />
            )}
          </div>
          <Link href="/zoeken">
            {/*<SearchBar waitTime={1000} />*/}
            <NavbarSearchIcon />
          </Link>
        </div>
        {/* {selected && selected?.children.length > 0 && (
          <HeaderSubmenu
            selected={selected?.name}
            categories={selected?.children}
          />
        )} */}
      </div>
      <div className="mobile-menu">
        <div className="inner">
          <div>
            <Link href="/">
              <Logo />
            </Link>
          </div>
          <div>
            {!isLoading && (
              <div className="flex items-center gap-6">
                {donate?.name && (
                  <div
                    className="rounded-[8px] px-1 lg:hidden"
                    style={{
                      backgroundColor: COLORS.primary.normal,
                    }}
                  >
                    <Link href={donate?.url || ""}>
                      <P className="m-[6px] text-center text-white">
                        {donate?.name}
                      </P>
                    </Link>
                  </div>
                )}
                <Link href="/zoeken">
                  <SearchIcon />
                </Link>
                <button
                  style={{ border: "none", backgroundColor: "white" }}
                  onClick={() => {
                    setSelected(sortedMenuItems[0]);
                    setMobileMenuOpen((isOpen) => !isOpen);
                  }}
                >
                  {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
                </button>
              </div>
            )}
          </div>
        </div>
        {mobileMenuOpen && (
          <div ref={mobileMenuRef}>
            <HeaderSubmenuMobile
              menuCols={sortedMenuItems}
              selected={selected}
              onChange={(x) => toggleMenu(x)}
            />
          </div>
        )}
      </div>
    </StyledHeader>
  );
}
