import { client } from "@/api/config";
import { Reaction } from "@/types/api/reaction.types";

/**
 * Reaction Service
 *
 * This service is responsible for handling all requests under the tag "Reaction".
 */
export const ReactionService = {
  /**
   * Create a new reaction
   * @param data the data to create the reaction with
   * @returns the created reaction
   */
  post: async (data: Reaction) => {
    const request = await client.post<number>("/reaction/reaction", data);
    return request.data;
  },
};
