import React, { useEffect, useState } from "react";
import { BiMessage } from "react-icons/bi";
import styled from "styled-components";

import Button from "@/components/buttons/Button";
import { LikeButton } from "@/components/buttons/LikeButton/LikeButton";
import Tag from "@/components/buttons/Tag/Tag";
import { H4 } from "@/components/typography";
import { parseDate } from "@/utils/parseDate";
import parseHTMLtoReact from "@/utils/parseHTMLtoReact";

type Props = {
  id?: string;
  name?: string | null;
  gender: string;
  age?: string | null;
  button?: boolean;
  authorType?: string;
  title: string;
  content: string;
  truncateContent?: boolean;
  showButton?: boolean;
  buttonUrl?: string;
  className?: string;
  tags?: string[];
  tagsBack?: string[];
  tagsInverse?: string[];
  tagsInverseBack?: string[];
  tagsHome?: string[];
  tagsHomeBack?: string[];
  tagsHomeInverse?: string[];
  tagsHomeInverseBack?: string[];
  comments?: number;
  postDate?: Date;
  image: any;
  likesCount?: number;
  commentsCount?: number;
  tagColor?: string;
  setLikesCount?: (_value: ((_prevState: number) => number) | number) => void;
  isBackSide?: boolean;
  isHomepage?: boolean;
};

type styledProps = {
  showButton?: boolean;
};

const StyledForumPost = styled.article<styledProps>`
  /* border: 1px solid ${({ theme }) => theme.colors.primary.normal}; */
  border-radius: 8px;
  padding: 24px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  border: 2px solid #3fc7b4;

  &.main-forum {
    background-color: ${({ theme, showButton }: any) =>
      showButton
        ? theme.colors.secondary.normal
        : theme.colors.primaryReversed.normal} !important;

    width: 100%;
  }

  height: 100%;

  p {
  }

  > div:last-child {
    display: flex;
    flex-direction: column;

    > div {
      height: 100%;
    }
  }

  .forum-tag {
    color: #fe517e !important;
  }

  &:hover {
    // background-color: ${({ theme }) => theme.colors.secondary.normal};
    border: 2px solid #3fc7b4;
    color: white;

    main-forum .forum-tag {
      background: #fff !important;
    }

    .main-forum .content {
      .forum-tags {
        span {
          background-color: ${({ theme }) => theme.colors.secondary.normal};
          color: white;
          font-weight: 400;
          font-size: 18px;
          font-family: "Fjalla One";
        }
      }
    }
  }

  header {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;

    p,
    h4 {
      color: white;
    }
  }

  .footer-button-section {
    position: absolute;
    height: unset;
    bottom: 24px;
    width: 100%;
    padding-right: 48px;
  }

  @media (max-width: 900px) {
    .footer-button-section {
      position: unset;
      padding-right: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 900px) {
    .footer-button-section {
      position: absolute;
      padding-right: 48px;
    }
  }

  .content {
    margin-bottom: 30px;
    font-size: ${({ theme }) => theme.fontSizes.p.desktop};

    @media (max-width: 768px) {
      margin-top: 0px;
    }

    color: #150f2f;

    > div {
      display: flex;
      gap: 8px;
      margin-bottom: 30px;
    }

    .forum-tags {
      height: 48px;
      overflow: hidden;

      span {
        padding: 8px 10px;
        background-color: white;
        border: none;
        height: 41px;
        color: #3fc7b4;
        font-weight: 400;
        font-size: 18px;
        font-family: "Fjalla One";
      }
    }
  }

  .forum-footer {
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #150f2f;

    .geplaatst {
      color: #150f2f;
    }

    .icon-wrapper {
      display: flex;

      gap: 5.55px;

      svg {
        cursor: pointer;
      }
    }

    p {
      margin: 0;
    }
  }

  .title {
    font-size: 32px !important;
    font-weight: 400;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  &.main-forum.forum-post.forum-list {
    .forum-tags span {
      color: #fff;
    }

    .back-act {
      background: #3fc7b4 !important;
      border: none !important;
    }
  }

  &.forum-detail {
    background-color: #3fc7b4 !important;

    h4,
    p,
    .content {
      color: #fff !important;
    }

    .content {
      margin-top: 30px;
      margin-bottom: 60px;
    }

    .forum-footer {
      bottom: unset;
      position: unset;
      margin-top: 24px;
      width: unset;

      footer {
        bottom: 24px;
        position: absolute;
        width: calc(100% - 48px);

        svg {
          stroke: #fff;
        }

        .comments svg path {
          fill: #fff;
        }
      }
    }
  }

  .custom_form_tags {
    height: 170px;
    overflow-y: auto !important;
  }

  @media (max-width: 767px) {
    .title {
      font-size: 24px !important;
    }
  }

  @media ${({ theme }) => theme.breakpoints.tablet} {
    .content {
      font-size: ${({ theme }) => theme.fontSizes.p.mobile};
    }
  }
`;

export default function ForumPost({
  id,
  button,
  title,
  content,
  age,
  comments = 0,
  postDate,
  truncateContent = true,
  showButton = false,
  buttonUrl = "",
  tags = [],
  tagsBack = [],
  tagsInverse = [],
  tagsInverseBack = [],
  tagsHome = [],
  tagsHomeBack = [],
  tagsHomeInverse = [],
  tagsHomeInverseBack = [],
  name,
  className,
  likesCount = 0,
  commentsCount,
  tagColor = "#3FC7B4",
  setLikesCount,
  isBackSide,
  isHomepage,
}: Props) {
  const [isPostLiked, setIsPostLiked] = useState(false);
  const ComponentTag = showButton ? "a" : "div";
  const props = showButton ? { href: buttonUrl } : {};

  useEffect(() => {
    const likedForumPosts: string[] = JSON.parse(
      localStorage.getItem("liked-forum-posts") || "[]",
    );
    setIsPostLiked(likedForumPosts.includes(id ?? ""));
  }, [likesCount, id]);

  const hasTags = () => {
    return (
      tags.length > 0 ||
      tagsBack.length > 0 ||
      tagsHome.length > 0 ||
      tagsHomeBack.length > 0
    );
  };

  const ForumFooterWithButtons = () => (
    <div className="footer-button-section mt-auto">
      <div className={"mb-[16px] flex justify-between"}>
        <div>
          <div className="icon-wrapper mr-4">
            <div className="likes flex gap-1">
              <LikeButton
                likeCount={likesCount}
                entityId={id ?? ""}
                entityType={"forum"}
                strokeColor={"#fff"}
                setLikesCount={setLikesCount}
              />
            </div>
            <div className="comments flex gap-1">
              <BiMessage size={24} />
              <p className="font-avenir text-[16px] font-light md:text-[18px]">
                {commentsCount}
              </p>
            </div>
          </div>
        </div>
        <div>
          {postDate && (
            <p className="geplaatst font-avenir text-lg font-light italic">
              {parseDate(postDate)}
            </p>
          )}
        </div>
      </div>
      <Button
        style={{
          backgroundColor: `white`,
          color: "#3fc7b4",
          fontWeight: `400`,
          fontSize: `18px`,
          border: "1px solid #fff",
        }}
        href={`${buttonUrl}#comment`}
      >
        Laat een reactie achter
      </Button>
      <Button
        style={{
          backgroundColor: `#3fc7b4`,
          color: "#fff !important",
          fontWeight: `400`,
          fontSize: `18px`,
          border: "1px solid #fff",
        }}
        href={buttonUrl}
        className={"forum-secondary-button"}
      >
        Lees verder
      </Button>
    </div>
  );

  return (
    <StyledForumPost
      style={truncateContent ? { minHeight: 540 } : {}}
      showButton={showButton}
      className={`main-forum ${className} flex flex-col overflow-hidden`}
    >
      {!!title && (
        <H4
          variant="bold"
          className={`title my-[30px] font-[32px] ${truncateContent ? "line-clamp-2" : ""}`}
        >
          {title}
        </H4>
      )}
      <p className="mt-[5px] text-lg font-extrabold text-black">
        {name}, {age?.includes("jaar") ? age : `${age} jaar`}
      </p>
      <div>
        <div className="content text-[16px] md:text-[18px]">
          {hasTags() && (
            <div className="forum-tags my-[14px] flex flex-wrap">
              {tags.length > 0 &&
                tags.map((item, index) => (
                  <Tag
                    key={item}
                    variant="dark"
                    size="m"
                    style={{
                      backgroundColor: "#3FC7B4",
                      borderColor: "#3FC7B4",
                    }}
                  >
                    {item}
                  </Tag>
                ))}
              {tagsBack.length > 0 &&
                tagsBack.map((item, index) => (
                  <Tag
                    key={item}
                    variant="light"
                    size="m"
                    style={{
                      backgroundColor: "#fff",
                      border: "2px solid #3FC7B4",
                      color: "#3FC7B4",
                    }}
                  >
                    {item}
                  </Tag>
                ))}
              {tagsHome.length > 0 &&
                tagsHome.map((item, index) => (
                  <Tag
                    key={item}
                    variant="dark"
                    size="m"
                    style={{
                      backgroundColor: "#fff",
                      borderColor: "#3FC7B4",
                      color: "#FE517E",
                    }}
                  >
                    {item}
                  </Tag>
                ))}
              {tagsHomeBack.length > 0 &&
                tagsHomeBack.map((item, index) => (
                  <Tag
                    key={item}
                    variant="light"
                    size="m"
                    style={{
                      backgroundColor: "#fff",
                      border: "2px solid #3FC7B4",
                      color: "#3FC7B4",
                    }}
                  >
                    {item}
                  </Tag>
                ))}
            </div>
          )}
          {!hasTags() && <div className={"h-[10px]"}></div>}
          <div
            className={`custom_forum_tags ${truncateContent ? (isBackSide ? "line-clamp-6 md:line-clamp-4" : "line-clamp-[11] md:line-clamp-[9]") : ""}`}
          >
            {parseHTMLtoReact(content)}
          </div>
        </div>
      </div>
      {button ? (
        <>
          {isHomepage ? (
            <ForumFooterWithButtons />
          ) : (
            <div className={"forum-footer md:bottom-[180px] md:w-full"}>
              <ForumFooterWithButtons />
            </div>
          )}
        </>
      ) : (
        <ComponentTag {...props} className="forum-footer">
          <footer>
            <div>
              <div className="icon-wrapper mr-4">
                <div className="likes flex gap-1">
                  <LikeButton
                    likeCount={likesCount}
                    entityId={id ?? ""}
                    entityType={"forum"}
                    setLikesCount={setLikesCount}
                  />
                </div>
                <div className="comments flex gap-1">
                  <BiMessage size={24} />
                  <p className="font-avenir text-[16px] font-light md:text-[18px]">
                    {commentsCount}
                  </p>
                </div>
              </div>
            </div>
            <div>
              {postDate && (
                <p className="geplaatst text-right font-avenir text-lg font-light italic">
                  {parseDate(postDate)}
                </p>
              )}
            </div>
          </footer>
        </ComponentTag>
      )}
    </StyledForumPost>
  );
}
