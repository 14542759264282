import Link from "next/link";
import React, { Fragment, useState } from "react";
import styled from "styled-components";

import ChevronDownWhite from "@/components/icons/ChevronDownWhite/ChevronDownWhite";
import ChevronUpWhite from "@/components/icons/ChevronUpWhite/ChevronUpWhite";
import { MenuItem } from "@/components/layout/Header/Header";
import HeaderSubmenu from "@/components/layout/Header/HeaderSubmenu/HeaderSubmenu";
import { P } from "@/components/typography";

type Props = {
  selected?: MenuItem;
  menuCols: MenuItem[];
  onChange: (x: MenuItem) => void;
  isForAdults: boolean;
};

const StyledNav = styled.nav`
  text-align: center;

  ul {
    display: flex;
    align-items: center;

    li {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 32px;
      cursor: pointer;

      p {
        margin-right: 8px;
      }

      &.selected {
        font-weight: 700;

        p {
          color: black;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    ul {
      li {
        margin-right: 44px;
      }
    }
  }

  @media ${({ theme }) => theme.devices.laptop} {
  }
`;

export default function HeaderNav({
  selected,
  onChange,
  menuCols,
  isForAdults,
}: Props) {
  const [hoveredMenu, setHoveredMenu] = useState<MenuItem | null>(null);

  const handleMouseEnter = (menu: MenuItem) => {
    setHoveredMenu(menu);
  };

  const handleMouseLeave = () => {
    setHoveredMenu(null);
  };

  return !isForAdults ? (
    <StyledNav>
      <ul>
        {menuCols.map((menu) => (
          <Fragment key={menu.id}>
            <li
              onMouseEnter={() => handleMouseEnter(menu)}
              onMouseLeave={handleMouseLeave}
            >
              {menu.children.map((child) => {
                return (
                  <div
                    key={child.name}
                    className="flex w-full items-center justify-center gap-8"
                  >
                    {child.children
                      .filter((grandchild) => grandchild.status === "published")
                      .map((grandchild) => {
                        if (grandchild.name === "Doneer nu") {
                          return (
                            <div
                              className="rounded-[8px] bg-teal px-1"
                              key={"donate-button"}
                            >
                              <Link href={grandchild.link}>
                                <P className="m-[6px] text-white">
                                  {grandchild.name}
                                </P>
                              </Link>
                            </div>
                          );
                        }

                        return (
                          <Link key={grandchild.name} href={grandchild.link}>
                            {grandchild.name
                              .split("<br>")
                              .map((part, index) => (
                                <p key={index} className={"inline-block"}>
                                  {part}
                                </p>
                              ))}
                          </Link>
                        );
                      })}
                  </div>
                );
              })}
            </li>
            {hoveredMenu?.id === menu.id && selected?.isForAdults && (
              <HeaderSubmenu
                selected={hoveredMenu?.name}
                categories={hoveredMenu?.children}
              />
            )}
          </Fragment>
        ))}
      </ul>
    </StyledNav>
  ) : (
    <StyledNav>
      <ul>
        {menuCols.map((menu) => (
          <Fragment key={menu.id}>
            {menu.children.length > 0 ? (
              <li
                onMouseEnter={() => handleMouseEnter(menu)}
                onMouseLeave={handleMouseLeave}
                className={"h-14"}
              >
                <p className={"font-fjalla-one uppercase text-white"}>
                  {menu.name}
                </p>{" "}
                <div className="mr-3">
                  {hoveredMenu?.id === menu.id ? (
                    <ChevronUpWhite />
                  ) : (
                    <ChevronDownWhite />
                  )}
                </div>
                {hoveredMenu?.id === menu.id && (
                  <HeaderSubmenu
                    selected={hoveredMenu?.name}
                    categories={hoveredMenu?.children}
                    isForAdults
                  />
                )}
              </li>
            ) : (
              <li className={"h-14"}>
                {menu.name === "Doneer nu" ? (
                  <div
                    className="rounded-[8px] border border-white px-1 text-white"
                    key={"donate-button"}
                  >
                    <Link href={menu.url}>
                      <p className="mx-1 py-1 font-fjalla-one uppercase">
                        {menu.name}
                      </p>
                    </Link>
                  </div>
                ) : (
                  <Link href={menu.url} target="_blank">
                    <P color={"white"}>{menu.name}</P>{" "}
                  </Link>
                )}
              </li>
            )}
          </Fragment>
        ))}
      </ul>
    </StyledNav>
  );
}
