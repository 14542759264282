import React, { FC } from "react";
import { FaChevronDown } from "react-icons/fa";
import styled from "styled-components";

import IconButton from "@/components/buttons/IconButton/IconButton";

interface IProps {
  marginTop: number;
  onClick?: () => Promise<void> | void | Promise<any>;
}

const ScrollDownWrapper = styled.div<IProps>`
  width: 100%;
  height: 1px;
  position: relative;
  background: blue;
  margin-top: ${(props) => props.marginTop + "px"};

  .scroll-down-cta-container {
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(2, 0, 36, 0) -3%,
      rgba(255, 255, 255, 1) 52%
    );
    height: 300px;
    z-index: 1000;
    width: 100%;
    bottom: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
    gap: 10px;

    .scroll-down-text {
      font-family: "Fjalla One";
      font-size: 18px;
    }

    .scroll-down-icon {
      cursor: pointer;
    }
  }
`;

const ScrollDown: FC<IProps> = ({ marginTop, onClick }) => {
  return (
    <ScrollDownWrapper marginTop={marginTop}>
      <div className="scroll-down-cta-container">
        <div className="scroll-down-text uppercase">Ontdek meer</div>
        <IconButton
          className="bottom-6 right-6 z-50 cursor-pointer rounded-full border-none bg-pink transition duration-300 ease-in-out hover:scale-110"
          Icon={FaChevronDown}
          iconColor={"#fff"}
          wrapperSize={44}
          iconSize={28}
          onClick={onClick}
        />
      </div>
    </ScrollDownWrapper>
  );
};

export default ScrollDown;
