import React, { useEffect, useState } from "react";
import { FaChevronUp } from "react-icons/fa";

import IconButton from "@/components/buttons/IconButton/IconButton";

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Show the button when the user has scrolled down a certain distance
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <IconButton
          className="fixed bottom-[70px] right-6 z-50 cursor-pointer rounded-full border-none bg-pink transition duration-300 ease-in-out hover:scale-110"
          Icon={FaChevronUp}
          iconColor={"#fff"}
          wrapperSize={44}
          iconSize={28}
          onClick={scrollToTop}
        />
      )}
    </div>
  );
};

export default BackToTopButton;
